<template>
  <a-modal
    width="620px"
    :dialog-style="{ display: 'none' }"
    :body-style="{ height: '220px', minWidth: '620px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', padding:'29px 40px' }"
    :footer="null"
    :visible="visible"
    :closable="true"
    @cancel="onCancel">
      <h1 class="txt-font-din-bold txt-32">{{ $t('components.titles.uploading') }}</h1>
      <a-progress
        :percent="progressPercentage"
        stroke-color="#66c300"
        :stroke-width="12"/>
      <p class="txt-16">{{ $t('components.description.thisCouldTakeUpToOneMinute') }}</p>
  </a-modal>
</template>
<script>
export default {
  name: 'Progress',
  props: {
    visible: {
      type: Boolean,
      default: undefined
    },
    onComplete: {
      type: Function,
      default: () => {}
    },
    onClose: {
      type: Function,
      default: () => {}
    },
    isUploaded: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      uploadId: null,
      progressPercentage: 0
    };
  },
  computed: {
  },
  created() {
    this.startUpload();
  },
  methods: {
    startUpload() {
      this.uploadId = setInterval(() => {
        // check for upload api completion
        if (this.isUploaded) {
          this.progressPercentage = 100;
          clearInterval(this.uploadId);
        } else {
          this.progressPercentage = Math.min(this.progressPercentage + 1, 90);
        }
      }, 1000);
    },
    onCancel() {
      clearInterval(this.uploadId);
      this.uploadId = null;
      this.onClose();
    }
  }
};
</script>
<style scoped>
</style>
